export const languages = [
    { code: 'af', name: '아프리칸스어' },
    { code: 'sq', name: '알바니아어' },
    { code: 'am', name: '암하라어' },
    { code: 'ar', name: '아랍어' },
    { code: 'hy', name: '아르메니아어' },
    { code: 'as', name: '아삼어' },
    { code: 'ay', name: '아이마라어' },
    { code: 'az', name: '아제르바이잔어' },
    { code: 'bm', name: '밤바라어' },
    { code: 'eu', name: '바스크어' },
    { code: 'be', name: '벨라루스어' },
    { code: 'bn', name: '벵골어' },
    { code: 'bho', name: '보지푸리어' },
    { code: 'bs', name: '보스니아어' },
    { code: 'bg', name: '불가리아어' },
    { code: 'ca', name: '카탈루냐어' },
    { code: 'ceb', name: '세부아노어' },
    { code: 'zh-CN', name: '중국어(간체)' },
    { code: 'zh-TW', name: '중국어(번체)' },
    { code: 'co', name: '코르시카어' },
    { code: 'hr', name: '크로아티아어' },
    { code: 'cs', name: '체코어' },
    { code: 'da', name: '덴마크어' },
    { code: 'dv', name: '디베히어' },
    { code: 'doi', name: '도그리어' },
    { code: 'nl', name: '네덜란드어' },
    { code: 'en', name: '영어' },
    { code: 'eo', name: '에스페란토' },
    { code: 'et', name: '에스토니아어' },
    { code: 'ee', name: '에웨어' },
    { code: 'fil', name: '필리핀어(타갈로그어)' },
    { code: 'fi', name: '핀란드어' },
    { code: 'fr', name: '프랑스어' },
    { code: 'fy', name: '프리지아어' },
    { code: 'gl', name: '갈리시아어' },
    { code: 'ka', name: '조지아어' },
    { code: 'de', name: '독일어' },
    { code: 'el', name: '그리스어' },
    { code: 'gn', name: '과라니어' },
    { code: 'gu', name: '구자라트어' },
    { code: 'ht', name: '아이티 크리올어' },
    { code: 'ha', name: '하우사어' },
    { code: 'haw', name: '하와이어' },
    { code: 'he', name: '히브리어' },
    { code: 'hi', name: '힌디어' },
    { code: 'hmn', name: '몽어' },
    { code: 'hu', name: '헝가리어' },
    { code: 'is', name: '아이슬란드어' },
    { code: 'ig', name: '이보어' },
    { code: 'ilo', name: '일로카노어' },
    { code: 'id', name: '인도네시아어' },
    { code: 'ga', name: '아일랜드어' },
    { code: 'it', name: '이탈리아어' },
    { code: 'ja', name: '일본어' },
    { code: 'jv', name: '자바어' },
    { code: 'kn', name: '칸나다어' },
    { code: 'kk', name: '카자흐어' },
    { code: 'km', name: '크메르어' },
    { code: 'rw', name: '키냐르완다어' },
    { code: 'gom', name: '콘칸어' },
    { code: 'ko', name: '한국어' },
    { code: 'kri', name: '크리오어' },
    { code: 'ku', name: '쿠르드어' },
    { code: 'ckb', name: '쿠르드어(소라니어)' },
    { code: 'ky', name: '키르기스어' },
    { code: 'lo', name: '라오어' },
    { code: 'la', name: '라틴어' },
    { code: 'lv', name: '라트비아어' },
    { code: 'ln', name: '링갈라어' },
    { code: 'lt', name: '리투아니아어' },
    { code: 'lg', name: '루간다어' },
    { code: 'lb', name: '룩셈부르크어' },
    { code: 'mk', name: '마케도니아어' },
    { code: 'mai', name: '마이틸리어' },
    { code: 'mg', name: '말라가시어' },
    { code: 'ms', name: '말레이어' },
    { code: 'ml', name: '말라얄람어' },
    { code: 'mt', name: '몰타어' },
    { code: 'mi', name: '마오리어' },
    { code: 'mr', name: '마라티어' },
    { code: 'mni-Mtei', name: '메이테이어(마니푸르어)' },
    { code: 'lus', name: '미조어' },
    { code: 'mn', name: '몽골어' },
    { code: 'my', name: '미얀마어(버마어)' },
    { code: 'ne', name: '네팔어' },
    { code: 'no', name: '노르웨이어' },
    { code: 'ny', name: '니안자어(치츄어)' },
    { code: 'or', name: '오리야어' },
    { code: 'om', name: '오로모어' },
    { code: 'ps', name: '파슈토어' },
    { code: 'fa', name: '페르시아어' },
    { code: 'pl', name: '폴란드어' },
    { code: 'pt', name: '포르투갈어(포르투갈, 브라질)' },
    { code: 'pa', name: '펀자브어' },
    { code: 'qu', name: '케추아어' },
    { code: 'ro', name: '루마니아어' },
    { code: 'ru', name: '러시아어' },
    { code: 'sm', name: '사모아어' },
    { code: 'sa', name: '산스크리트어' },
    { code: 'gd', name: '스코틀랜드 게일어' },
    { code: 'nso', name: '북소토어' },
    { code: 'sr', name: '세르비아어' },
    { code: 'st', name: '세소토어' },
    { code: 'sn', name: '쇼나어' },
    { code: 'sd', name: '신디어' },
    { code: 'si', name: '스리랑카어(싱할라어)' },
    { code: 'sk', name: '슬로바키아어' },
    { code: 'sl', name: '슬로베니아어' },
    { code: 'so', name: '소말리어' },
    { code: 'es', name: '스페인어' },
    { code: 'su', name: '순다어' },
    { code: 'sw', name: '스와힐리어' },
    { code: 'sv', name: '스웨덴어' },
    { code: 'tl', name: '타갈로그어(필리핀어)' },
    { code: 'tg', name: '타지크어' },
    { code: 'ta', name: '타밀어' },
    { code: 'tt', name: '타타르어' },
    { code: 'te', name: '텔루구어' },
    { code: 'th', name: '태국어' },
    { code: 'ti', name: '티그리냐어' },
    { code: 'ts', name: '총가어' },
    { code: 'tr', name: '튀르키예어' },
    { code: 'tk', name: '투르크멘어' },
    { code: 'ak', name: '트위어(아칸어)' },
    { code: 'uk', name: '우크라이나어' },
    { code: 'ur', name: '우르두어' },
    { code: 'ug', name: '위구르어' },
    { code: 'uz', name: '우즈베크어' },
    { code: 'vi', name: '베트남어' },
    { code: 'cy', name: '웨일즈어' },
    { code: 'xh', name: '코사어' },
    { code: 'yi', name: '이디시어' },
    { code: 'yo', name: '요루바어' },
    { code: 'zu', name: '줄루어' },
  ];