import React, { useState, useEffect } from 'react';
import axios from './common/axios';
import { languages } from './languages';
import {
  Container,
  Typography,
  Box,
  Button,
  Paper,
  TextField,
  Autocomplete,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  InputLabel,
  Select,
  MenuItem,
  LinearProgress,
} from '@mui/material';
import { Mic, Stop, AttachFile } from '@mui/icons-material';


function App() {
  const [audioFile, setAudioFile] = useState(null);
  const [inputText, setInputText] = useState('');
  const [translation, setTranslation] = useState('');
  const [targetLanguage, setTargetLanguage] = useState('en');
  const [sourceLanguage, setSourceLanguage] = useState('ko');
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioInputType, setAudioInputType] = useState('upload');
  const [speakerDiarizationOption, setSpeakerDiarizationOption] = useState('Off');
  const [diarizationSpeakerCount, setDiarizationSpeakerCount] = useState(2);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);


  const handleAudioInputTypeChange = (event) => {
    setAudioInputType(event.target.value);
  };

  const handleFileChange = (e) => {
    setAudioFile(e.target.files[0]);
  };

  const handleTextChange = (e) => {
    setInputText(e.target.value);
  };

  const handleLanguageChange = (e, value) => {
    setTargetLanguage(value ? value.code : '');
  };

  const handleLanguageChange2 = (e, value) => {
    setSourceLanguage(value ? value.code : '');
  };

  const handleStartRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        const recorder = new MediaRecorder(stream);
        setMediaRecorder(recorder);
        recorder.start();
        setIsRecording(true);
      })
      .catch((error) => {
        console.error('마이크 접근 오류:', error);
        alert('마이크 접근에 오류가 발생했습니다.');
      });
  };

  useEffect(() => {
    if (audioFile) {
      handleUpload();
    }
  }, [audioFile]);



  const handleStopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setIsRecording(false);
      mediaRecorder.ondataavailable = (event) => {
        const audioBlob = event.data;
        const audioFile = new File([audioBlob], 'recording.webm', { type: 'audio/webm' });
        setAudioFile(audioFile);
      };
    }
  };

  const handleUpload = async () => {
    if (!audioFile) {
      return;
    }

    try {
      const formData = new FormData();
      formData.append('audio', audioFile);
      formData.append('sourceLanguage', sourceLanguage);
      formData.append('enableSpeakerDiarization', speakerDiarizationOption === 'Multiple Speakers');
      if (speakerDiarizationOption === 'Multiple Speakers') {
        formData.append('diarizationSpeakerCount', diarizationSpeakerCount);
      }

      setIsUploading(true);
      const response = await axios.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(progress);
        },
      });
      const transcriptionText = response.data.transcription;
      setInputText(transcriptionText);
      setIsUploading(false);
      setUploadProgress(0);
    } catch (error) {
      console.error('오류 발생:', error);
      alert('오류가 발생했습니다.');
      setIsUploading(false);
      setUploadProgress(0);
    }
  };


  const handleTranslate = async () => {
    if (!inputText) {
      alert('텍스트를 입력해주세요.');
      return;
    }

    try {
      const translateResponse = await axios.post('/translate', {
        text: inputText,
        targetLanguage: targetLanguage,
      });

      setTranslation(translateResponse.data.translation);
    } catch (error) {
      console.error('오류 발생:', error);
      alert('오류가 발생했습니다.');
    }
  };

  return (
    <Container maxWidth="sm">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          오디오 파일 업로드 및 번역
        </Typography>
        <form onSubmit={handleUpload}>
          <Box mb={2}>
            <FormControl component="fieldset">
              {/* <FormLabel component="legend">오디오 입력 방식:</FormLabel> */}
              <Box display="flex" alignItems="center">
                <RadioGroup
                  row
                  aria-label="audio-input-type"
                  name="audio-input-type"
                  value={audioInputType}
                  onChange={handleAudioInputTypeChange}
                >
                  <FormControlLabel value="upload" control={<Radio />} label="파일 업로드" />
                  <FormControlLabel value="record" control={<Radio />} label="녹음" />
                </RadioGroup>
                {audioInputType === 'upload' && (
                  <Box ml={2}>
                    <input
                      accept=".mp3,.wav,.m4a"
                      style={{ display: 'none' }}
                      id="audio-file"
                      type="file"
                      onChange={handleFileChange}
                    />
                    <label htmlFor="audio-file">
                      <Button variant="outlined" component="span" startIcon={<AttachFile />}>
                        오디오 파일 선택
                      </Button>
                    </label>
                  </Box>
                )}
                {audioInputType === 'record' && (
                  <Box ml={2}>
                    <IconButton
                      color={isRecording ? 'secondary' : 'primary'}
                      onClick={isRecording ? handleStopRecording : handleStartRecording}
                    >
                      {isRecording ? <Stop /> : <Mic />}
                    </IconButton>
                  </Box>
                )}
              </Box>
            </FormControl>
          </Box>
          {audioFile && (
          <Box mt={1}>
            <Typography variant="body2">선택된 파일: {audioFile.name}</Typography>
          </Box>
        )}
        {isUploading && (
          <Box mt={1}>
            <Typography variant="body2">업로드 진행률: {uploadProgress}%</Typography>
            <Box mt={1}>
              <LinearProgress variant="determinate" value={uploadProgress} />
            </Box>
          </Box>
        )}
      

          <Box mt={4} mb={2} display="flex" alignItems="center">
            <Box mr={2} flexGrow={1}>
              <FormControl fullWidth>
                <InputLabel id="speaker-diarization-label">화자 분할</InputLabel>
                <Select
                  labelId="speaker-diarization-label"
                  value={speakerDiarizationOption}
                  label="화자 분할"
                  onChange={(e) => setSpeakerDiarizationOption(e.target.value)}
                >
                  <MenuItem value="Off">Off</MenuItem>
                  <MenuItem value="Multiple Speakers">여러명의 화자</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {speakerDiarizationOption === 'Multiple Speakers' && (
              <Box flexGrow={1}>
                <FormControl fullWidth>
                  <InputLabel id="speaker-count-label">화자 수</InputLabel>
                  <Select
                    labelId="speaker-count-label"
                    value={diarizationSpeakerCount}
                    label="화자 수"
                    onChange={(e) => setDiarizationSpeakerCount(e.target.value)}
                  >
                    {[2, 3, 4, 5].map((count) => (
                      <MenuItem key={count} value={count}>{count}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
          </Box>

 
          <Box mb={2}>
            <Typography variant="subtitle1" gutterBottom>텍스트 입력:</Typography>
            <TextField
              multiline
              rows={4}
              value={inputText}
              onChange={handleTextChange}
              fullWidth
            />
          </Box>
          <Box mb={2}>
            <Autocomplete
              options={languages}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="도착 언어" />}
              onChange={handleLanguageChange}
              value={languages.find((lang) => lang.code === targetLanguage)}
            />
          </Box>
        </form>
        <Box mb={2}>
          <Button disabled={targetLanguage === ""} variant="contained" color="primary" onClick={handleTranslate}>
            텍스트 번역
          </Button>
        </Box>
        {translation && (
          <Box mt={4}>
            <Paper variant="outlined" sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>번역된 텍스트:</Typography>
              <Typography>{translation}</Typography>
            </Paper>
          </Box>
        )}
      </Box>
    </Container>
  );
}

export default App;